<template>
  <div class="content">
    <h2 class="title">
      合同指派通知
      <div>
        <span v-if="auditStatus === 'accepted'" class="receive"><i></i>已接受</span>
        <span v-if="auditStatus === 'rejected'" class="reject"><i></i>已拒绝</span>
      </div>
    </h2>
    <p class="hint-info" v-if="notice==0">
      <b>{{operator.operatorUser}}<span>-{{operator.operatorBm}}</span></b>
      将如下合同指派给你，请确认是否接受该合同及对应权限指派？
    </p>
    <p class="hint-info"  v-if="notice==1">
      <span v-if="auditStatus !== 'rejected'">
        <b>{{operator.operatorUser}}<span>-{{operator.operatorBm}}</span></b>
        将如下合同指派给
        <b style="font-size:12px">{{designee.designeeUser}}<span>-{{designee.designeeBm}}</span></b>
        请您知悉
      </span>
      <span v-else>
        <b style="font-size:12px">{{designee.designeeUser}}<span>-{{designee.designeeBm}}</span></b>
        拒绝了您的合同指派，本次合同指派未生效
      </span>
    </p>
    <div class="detail-card">
      <h2 class="title-card">
        合同信息
      </h2>
      <el-table :data="tableData" class="contract-table" border>
        <el-table-column label="合同名称" prop="contractName" width="258"></el-table-column>
        <el-table-column label="经办人" prop="operatorName" width="258"></el-table-column>
        <el-table-column label="合同类型" prop="contractType" width="258"></el-table-column>
        <el-table-column label="指派权限" prop="assignmentlimit"></el-table-column>
      </el-table>
    </div>
    <div class="detail-card noborder">
      <h2 class="title-card">
        该合同相关的其他合同
      </h2>
      <el-table :data="tableData2" class="contract-table" empty-text="该合同下暂无相关的其他合同" border>
        <el-table-column label="合同名称" prop="contractName"></el-table-column>
        <el-table-column label="经办人" prop="operatorName" width="258"></el-table-column>
        <el-table-column label="合同类型" prop="contractType" width="258"></el-table-column>
        <el-table-column label="指派权限" prop="assignmentlimit" width="258"></el-table-column>
      </el-table>
    </div>
    <div class="inline"></div>
    <footer v-if="notice==0">
      <!-- <div v-if="!auditStatus"> -->
      <div v-if="auditStatus !== 'rejected' && auditStatus !== 'accepted'">
        <span @click="handleClick('2')">拒绝</span>
        <span @click="handleClick('1')">接受</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      tableData2: [],
      bizId: '',
      operator: {},
      designee: {},
      notice: '',
      loading: '',
      auditStatus: ''
    }
  },
  created() {
    const query = this.$route.query
    this.notice = query.notice || ''
    this.bizId = query.bizId || ''
    this.init()
  },
  methods: {
    init() {
      this.$request.get(this.URL.getAssignmentInfo + '?bizId=' + this.bizId).then(res => {
        if (res.code === '200') {
          this.tableData.push(res.data.contract)
          this.tableData2 = res.data.agreements
          // this.auditStatus = (res.data.auditStatus === 'accepted' || res.data.auditStatus === 'rejected')
          this.auditStatus = res.data.auditStatus
          const _arr = res.data.operatorUser?.split('（')
          const _deins = res.data.designeeUser?.split('（')
          this.operator = {
            'operatorUser': _arr[0],
            'operatorBm': _arr[1]?.substr(0, _arr[1].length - 1)
          }
          this.designee = {
            'designeeUser': _deins[0],
            'designeeBm': _deins[1].substr(0, _deins[1].length - 1)
          }
        }
      })
    },
    handleClick(type) {
      this.sendTodoAction(type)
    },
    sendTodoAction(id) {
      this.loading = this.$loading({ 
        fullscreen: true,
        background: "rgba(255, 255, 255, 0.8)",
        text: "加载中..."
      })
      this.$request.get(this.URL.todoAction + '?todoId=' + this.bizId + '&todoStatus=' + id).then(res => {
        console.log(res)
        this.loading.close()
        if (res.code === '200') {
          this.$message({
            message: res.data,
            type: 'success'
          })
          window.parent.window.close()
          // window.location.href="about:blank"
          // window.close()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  h2.title{
    font-size: 18px;
    color: #323232;
    font-weight: 600px;
    padding: 12px 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
    div{
      display: inline-block;
      margin-left: 12px;
      span{
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        width: 60px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background: #E0F7F1;
        color: #50AC94;
        border-radius: 2px;
        &.reject{
          background: #FBEEEF;
          color: #FA5F87;
          i{
            background: #FA5F87;
          }
        }
        i{
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 6px;
          background: #50AC94;
          vertical-align: middle;
          margin-top: -3px;
        }
      }
    }
  }
  .hint-info{
    color:#997236;
    font-size: 12px;
    font-weight: 400;
    line-height: 42px;
    height: 42px;
    width: calc(100% - 36px);
    margin: 18px 18px;
    background: #FBF5E9;
    padding-left: 18px;
    box-sizing: border-box;
    b{
      font-size: 14px;
      font-weight: 600;
      span{
        font-size: 12px;
      }
    }
  }
  .detail-card{
    margin-bottom: 20px;
    padding: 18px;
    padding-top: 0px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebebeb;
    &.noborder{
      border-bottom: none;
      margin-bottom: 0;
    }
    .title-card{
      position: relative;
      font-size: 16px;
      color: #323232;
      &::before{
        content: "";
        display: block;
        width: 3px;
        height: 18px;
        background-color: #efd193;
        position: absolute;
        left: -18px;
        top: 2px;
      }
    }
    .contract-table{
      margin-top: 9px;
    }
  }
  .inline{
    width:100%;
    height:100px;
    background-color: #edf0f7;
  }
  footer{
    div{
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 54px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        width: 72px;
        height: 36px;
        line-height: 36px;
        background: #F0D8A8;
        text-align: center;
        color: #323232;
        border-radius: 2px;
        cursor: pointer;
        box-sizing: border-box;
        &:last-child{
          margin-left: 12px;
        }
        &:first-child{
          background: #fff;
          color: #997236;
          border: 1px solid #997236;
        }
      }
    }
    
  }
}
</style>